
import { defineComponent, ref } from "vue";
import HomeHeader from "@/components/HomeHeader.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    HomeHeader,
  },
  setup() {},
});
