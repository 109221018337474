
import { defineComponent, reactive, ref, toRefs } from 'vue';
import { onBeforeRouteUpdate, useRouter } from 'vue-router';

export default defineComponent({
	name: 'HomeHeader',
	setup() {
		const headerList = ref([
			{
				key: '1',
				name: 'HomeMain',
			},
			{
				key: '3',
				name: 'ServiceSupport',
			},
			{
				key: '4',
				name: 'PartnerYj',
			},
			{
				key: '5',
				name: 'IntroduceYjkj',
			},
		]);
		const activeIndex = ref('1');

		const router = useRouter();
		const routerName = router.currentRoute.value.name as string;
		if (routerName != '') {
			if (routerName == 'SolutionView') {
				router.push({
					name: 'SolutionView',
					params: {
						id: '2-1',
					},
				});
				activeIndex.value = '2-1';
			} else {
				router.push({
					name: routerName,
				});
				let result = headerList.value.filter((ele) => ele.name == routerName);
				if (result.length > 0) activeIndex.value = result[0].key;
			}
		}
		const handleSelect = (key: string, keyPath: string[]) => {
			switch (key) {
				case '1':
					router.push({
						name: 'HomeMain',
					});
					break;
				case '2-1':
				case '2-2':
				case '2-3':
				case '2-4':
				case '2-5':
				case '2-6-1':
				case '2-6-2':
					router.push({
						name: 'SolutionView',
						params: {
							id: key,
						},
					});
					break;
				case '3':
					router.push({
						name: 'ServiceSupport',
					});
					break;
				case '4':
					router.push({
						name: 'PartnerYj',
					});
					break;
				case '5':
					router.push({
						name: 'IntroduceYjkj',
					});
					break;
				default:
					router.push({
						name: 'about',
					});
					break;
			}
			data.drawer = false;
		};
		const data = reactive({
			log: require('@/assets/images/indexlogo.png'),
			apply: () => {
				router.push({
					name: 'ApplicationExperience',
				});
				activeIndex.value = '';
				data.drawer = false;
			},
			drawer: false,
			direction: 'rtl',
			openright: () => {
				data.drawer = true;
			},
		});
		onBeforeRouteUpdate((to: any, from: any) => {
			let router_name = to.name;
			if (router_name == 'SolutionView') {
				// router.push({
				// 	name: "SolutionView",
				// 	params: {
				// 		id: "2-1",
				// 	},
				// });
				activeIndex.value =
					to.params && to.params.id != '' ? to.params.id : '2-1';
			} else {
				// router.push({
				// 	name: router_name,
				// });
				let result = headerList.value.filter((ele) => ele.name == router_name);
				if (result.length > 0) activeIndex.value = result[0].key;
			}
		});
		return {
			activeIndex,
			handleSelect,
			...toRefs(data),
		};
	},
});
