import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'home',
		component: HomeView,
		children: [
			{
				path: '',
				name: 'HomeMain',
				component: () => import('../views/home/HomeMain.vue'),
			},
			{
				path: 'solution/:id',
				name: 'SolutionView',
				component: () => import('../views/SolutionView.vue'),
				props: (route) => ({ formProps: route.params }),
			},
			{
				path: 'IntroduceYjkj',
				name: 'IntroduceYjkj',
				component: () =>
					import(
						/* webpackChunkName: "about" */ '@/views/home/IntroduceYj.vue'
					),
			},
			{
				path: 'PartnerYj',
				name: 'PartnerYj',
				component: () =>
					import(/* webpackChunkName: "about" */ '@/views/home/PartnerYj.vue'),
			},
			{
				path: 'ServiceSupport',
				name: 'ServiceSupport',
				component: () =>
					import(
						/* webpackChunkName: "about" */ '@/views/home/ServiceSupport.vue'
					),
			},
			{
				path: 'ApplicationExperience',
				name: 'ApplicationExperience',
				component: () =>
					import(
						/* webpackChunkName: "about" */ '@/views/home/ApplicationExperience.vue'
					),
			},
		],
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
