import { createApp } from 'vue';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import './common/font.css';
import './style.css';

// ----------------------------------
axios.defaults.baseURL =
	process.env.NODE_ENV === 'production'
		? 'http://erp.ghaomc.com/api/v1'
		: 'http://192.168.14.2:8768/api/v1';
console.log(axios.defaults.baseURL);

const app = createApp(App);

app.use(ElementPlus, store).use(router);
app.mount('#app');
